// // V1 OF THE MODAL JS
// // Function to open the modal
// function openModal(content) {
//   const modal = document.getElementById('teamRoundModal');
//   const modalBody = document.getElementById('modal-body');
//   modalBody.innerHTML = content;
//   modal.style.display = 'flex';
// }

// // Function to close the modal
// function closeModal() {
//   const modal = document.getElementById('teamRoundModal');
//   const modalBody = document.getElementById('modal-body');
//   modal.style.display = 'none';
//   modalBody.innerHTML = '';
// }

// // Add event listeners for the table rows (instead of just links)
// document.addEventListener('DOMContentLoaded', function () {
//   document.querySelectorAll('.team-round-link').forEach(function (row) {
//     row.addEventListener('click', function (event) {
//       event.preventDefault();
      
//       // Get the team round ID from the data attribute
//       const teamRoundId = this.dataset.teamRoundId;
      
//       // Make a request to fetch the team round content
//       fetch(`/team_round_scorecard/${teamRoundId}`)
//         .then(response => response.text())
//         .then(html => {
//           // Open the modal with the fetched content
//           openModal(html);
//         })
//         .catch(error => console.error('Error loading team round content:', error));
//     });
//   });
// });

// // Close the modal if clicked outside the content or on the close button
// document.addEventListener('click', function (event) {
//   const modal = document.getElementById('teamRoundModal');
//   const modalContent = document.querySelector('.modal-dialog');
//   const modalCloseButton = document.getElementById('modal-close');

//   // Check if the click is outside the modal content or on the close button
//   // if ((event.target != modalContent && !modalContent.contains(event.target)) || event.target === modalCloseButton) {
//   //   closeModal();
//   // }
//   if ((event.target != modalContent && !modalContent.contains(event.target)) || event.target === modalCloseButton) {
//     closeModal();
//   }
// });


// // V2 OF THE MODAL JS
// // Function to open the modal
// function openModal(content) {
//   const modal = document.getElementById('teamRoundModal');
//   const modalBody = document.getElementById('modal-body');
//   modalBody.innerHTML = content;
//   modal.style.display = 'flex';
// }

// // Function to close the modal
// function closeModal() {
//   const modal = document.getElementById('teamRoundModal');
//   const modalBody = document.getElementById('modal-body');
//   modal.style.display = 'none';
//   modalBody.innerHTML = '';
// }

// // Add event listeners when the DOM is fully loaded
// document.addEventListener('DOMContentLoaded', function () {
//   // Event listener for opening the modal
//   document.querySelectorAll('.team-round-link').forEach(function (row) {
//     row.addEventListener('click', function (event) {
//       event.preventDefault();
//       const teamRoundId = this.dataset.teamRoundId;
//       fetch(`/team_round_scorecard/${teamRoundId}`)
//         .then(response => response.text())
//         .then(html => {
//           openModal(html);
//           // Add event listener for the close button after the content is loaded
//           const closeButton = document.getElementById('modal-close');
//           if (closeButton) {
//             closeButton.addEventListener('click', closeModal);
//           }
//         })
//         .catch(error => console.error('Error loading team round content:', error));
//     });
//   });

//   // Event listener for closing the modal when clicking outside
//   const modal = document.getElementById('teamRoundModal');
//   modal.addEventListener('click', function (event) {
//     if (event.target === modal) {
//       closeModal();
//     }
//   });
// });

// V3 OF THE MODAL JS
const TeamRoundModal = (function() {
  let modal, modalBody, openLinks;

  function init() {
    modal = document.getElementById('teamRoundModal');
    modalBody = document.getElementById('modal-body');
    openLinks = document.querySelectorAll('.team-round-link');

    if (!modal || !modalBody) {
      console.error('Modal elements not found');
      return;
    }

    attachEventListeners();
  }

  function attachEventListeners() {
    // Event listeners for opening the modal
    openLinks.forEach(link => {
      link.addEventListener('click', handleOpenClick);
    });

    // Event listener for closing the modal when clicking outside
    modal.addEventListener('click', handleOutsideClick);
  }

  function handleOpenClick(event) {
    event.preventDefault();
    event.stopPropagation(); // Prevent event from bubbling up
    const teamRoundId = event.currentTarget.dataset.teamRoundId;
    fetchAndOpenModal(teamRoundId);
  }

  function handleOutsideClick(event) {
    if (event.target === modal) {
      closeModal();
    }
  }

  function fetchAndOpenModal(teamRoundId) {
    fetch(`/team_round_scorecard/${teamRoundId}`)
      .then(response => response.text())
      .then(html => {
        openModal(html);
      })
      .catch(error => console.error('Error loading team round content:', error));
  }

  function openModal(content) {
    modalBody.innerHTML = content;
    modal.style.display = 'flex';
    attachCloseListener();
  }

  function closeModal() {
    modal.style.display = 'none';
    modalBody.innerHTML = '';
  }

  function attachCloseListener() {
    const closeButton = document.getElementById('modal-close');
    if (closeButton) {
      closeButton.addEventListener('click', closeModal);
    }
  }

  // Public API
  return {
    init: init
  };
})();

// Initialize on DOMContentLoaded and turbolinks:load if available
document.addEventListener('DOMContentLoaded', TeamRoundModal.init);
document.addEventListener('turbolinks:load', TeamRoundModal.init);