// Import external libraries or custom JavaScript files
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../channels";

// Initialize Rails, Turbolinks, and ActiveStorage
Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Import custom JavaScript files
import "../src/team_rounds_modal.js";
import "../src/menu.js";
import "../src/flash_alerts.js";
import "../src/score_validation.js";
import '../stylesheets/application.scss';
