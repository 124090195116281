document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('form');
  const inputs = Array.from(document.querySelectorAll('input[type="text"][inputmode="numeric"]'));
  const errorMessage = document.getElementById('score-error');

  function validateInput(input) {
    const errorIcon = input.parentElement.querySelector('svg');
    const isValid = /^\d+$/.test(input.value);

    if (!isValid && input.value !== '') {
      input.classList.remove('ring-gray-300', 'focus:ring-blue-600');
      input.classList.add('text-red-900', 'ring-red-300', 'focus:ring-red-500');
      errorIcon.classList.remove('hidden');
      errorMessage.classList.remove('hidden');
    } else {
      input.classList.remove('text-red-900', 'ring-red-300', 'focus:ring-red-500');
      input.classList.add('ring-gray-300', 'focus:ring-blue-600');
      errorIcon.classList.add('hidden');
      if (inputs.every(input => /^\d+$/.test(input.value) || input.value === '')) {
        errorMessage.classList.add('hidden');
      }
    }
  }

  function autoAdvance(currentInput) {
    const currentIndex = inputs.indexOf(currentInput);
    if (currentIndex < inputs.length - 1) {
      inputs[currentIndex + 1].focus();
      inputs[currentIndex + 1].select(); // Select any existing content for easy overwriting
    }
  }

  inputs.forEach((input) => {
    // Ensure numeric keypad on mobile
    input.inputMode = 'numeric';
    input.pattern = '\\d*';

    let initialValue = input.value;

    input.addEventListener('focus', function() {
      initialValue = this.value;
      // Select the content when focused, for easy overwriting
      this.select();
    });

    input.addEventListener('input', function() {
      if (this.value === '') {
        this.classList.remove('text-red-900', 'ring-red-300', 'focus:ring-red-500');
        this.classList.add('ring-gray-300', 'focus:ring-blue-600');
        this.parentElement.querySelector('svg').classList.add('hidden');
        if (inputs.every(input => input.value === '')) {
          errorMessage.classList.add('hidden');
        }
      } else if (/^\d+$/.test(this.value) && this.value !== '1' && initialValue === '') {
        // Delay the auto-advance slightly to allow for multi-digit entry
        setTimeout(() => {
          if (this.value.length === 1 || this.value.length === 2) {
            autoAdvance(this);
          }
        }, 10);
      }
    });

    input.addEventListener('blur', function() {
      validateInput(this);
    });

    // Handle Enter key
    input.addEventListener('keydown', function(e) {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission
        autoAdvance(this);
      }
    });
  });

  form.addEventListener('submit', function (event) {
    let isValid = true;
    inputs.forEach(input => {
      validateInput(input);
      if (!/^\d+$/.test(input.value) && input.value !== '') {
        isValid = false;
      }
    });
    if (!isValid) {
      event.preventDefault();
    }
  });
});