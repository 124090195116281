// // V1 of menu.js
// document.addEventListener('DOMContentLoaded', () => {
//   const menuButton = document.querySelector('[aria-controls="mobile-menu"]');
//   const mobileMenu = document.getElementById('mobile-menu');

//   menuButton?.addEventListener('click', () => {
//     const isExpanded = menuButton.getAttribute('aria-expanded') === 'true' || false;
//     menuButton.setAttribute('aria-expanded', !isExpanded);
//     mobileMenu.classList.toggle('hidden');
    
//     // Toggle the icons (close/open)
//     menuButton.querySelector('svg.block').classList.toggle('hidden');
//     menuButton.querySelector('svg.hidden').classList.toggle('block');
//   });
// });


// V2 of menu.js
const MobileMenu = (function() {
  let menuButton, mobileMenu;

  function init() {
      menuButton = document.querySelector('[aria-controls="mobile-menu"]');
      mobileMenu = document.getElementById('mobile-menu');

      if (!menuButton || !mobileMenu) {
          console.error('Mobile menu elements not found');
          return;
      }

      attachEventListeners();
  }

  function attachEventListeners() {
      menuButton.addEventListener('click', toggleMenu);
  }

  function toggleMenu() {
      const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';
      const newExpandedState = !isExpanded;
      menuButton.setAttribute('aria-expanded', newExpandedState);
      mobileMenu.classList.toggle('hidden');

      // Toggle the icons (close/open)
      const openIcon = menuButton.querySelector('svg:first-child');
      const closeIcon = menuButton.querySelector('svg:last-child');

      if (openIcon && closeIcon) {
          if (newExpandedState) {
              // Menu is being opened
              openIcon.classList.add('hidden');
              openIcon.classList.remove('block');
              closeIcon.classList.add('block');
              closeIcon.classList.remove('hidden');
          } else {
              // Menu is being closed
              openIcon.classList.add('block');
              openIcon.classList.remove('hidden');
              closeIcon.classList.add('hidden');
              closeIcon.classList.remove('block');
          }
      }
  }

  // Public API
  return {
      init: init
  };
})();

// Desktop User Dropdown Menu Module
const DesktopUserMenu = (function() {
    let menuButton, dropdownMenu;

    function init() {
        menuButton = document.getElementById('user-menu-button');
        dropdownMenu = document.querySelector('.dropdown-menu');
        
        if (!menuButton || !dropdownMenu) {
            console.error('User menu elements not found');
            return;
        }

        attachEventListeners();
    }

    function attachEventListeners() {
        menuButton.addEventListener('click', toggleDropdown);
        document.addEventListener('click', closeDropdownOnClickOutside);
    }

    function toggleDropdown(event) {
        event.stopPropagation();
        const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';
        menuButton.setAttribute('aria-expanded', !isExpanded);
        dropdownMenu.classList.toggle('hidden');

        if (!dropdownMenu.classList.contains('hidden')) {
            // Add animation classes when showing
            dropdownMenu.classList.add('transition', 'ease-out', 'duration-100', 'transform', 'opacity-100', 'scale-100');
            dropdownMenu.classList.remove('opacity-0', 'scale-95');
        } else {
            // Add animation classes when hiding
            dropdownMenu.classList.add('transition', 'ease-in', 'duration-75', 'transform', 'opacity-0', 'scale-95');
            dropdownMenu.classList.remove('opacity-100', 'scale-100');
        }
    }

    function closeDropdownOnClickOutside(event) {
        if (!menuButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
            menuButton.setAttribute('aria-expanded', 'false');
            dropdownMenu.classList.add('hidden');
            // Add animation classes when hiding
            dropdownMenu.classList.add('transition', 'ease-in', 'duration-75', 'transform', 'opacity-0', 'scale-95');
            dropdownMenu.classList.remove('opacity-100', 'scale-100');
        }
    }

    // Public API
    return {
        init: init
    };
})();

// Initialize both modules
document.addEventListener('DOMContentLoaded', function() {
    MobileMenu.init();
    DesktopUserMenu.init();
});

document.addEventListener('turbolinks:load', function() {
    MobileMenu.init();
    DesktopUserMenu.init();
});