document.addEventListener('DOMContentLoaded', function () {
  // Automatically close the flash message after 5 seconds
  setTimeout(function () {
    const flashMessages = document.querySelectorAll('.flash-message');
    flashMessages.forEach(function (flashMessage) {
      flashMessage.style.display = 'none';
    });
  }, 5000); // 5 seconds

  // Close flash messages when the dismiss button is clicked
  const dismissButtons = document.querySelectorAll('.flash-message .dismiss-button');
  dismissButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      const flashMessage = this.closest('.flash-message');
      flashMessage.style.display = 'none';
    });
  });
});
